import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { getTopImage } from "@utils/imageHelpers/topImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import { IImageSrc } from "./ImageSources";
import ImageSrcSet from "./ImageSources";
import topImageStyles from "./IvTopImage.module.css";

interface IImage {
  image: any;
  contentfulPreviewEntryId?: string;
  contentfulPreviewImageFieldId?: string;
}

const IvTopImage = ({ image }: IImage): JSX.Element => {
  if (image) {
    const srcSet: IImageSrc[] = [
      {
        url: replaceImageVaultBasePath(getTopImage(image, 767)?.url),
        media: "(max-width: 768px)",
      },
      {
        url: replaceImageVaultBasePath(getTopImage(image, 1279)?.url),
        media: "(max-width: 1280px)",
      },
      { url: replaceImageVaultBasePath(getTopImage(image, 1919)?.url) },
    ];
    const fallbackImage = getTopImage(image, 767);
    const metadata = getImageMetaData(image);

    return (
      <picture className={topImageStyles.topImage}>
        <ImageSrcSet imageArray={srcSet} />
        <img
          src={replaceImageVaultBasePath(fallbackImage?.url)}
          alt={metadata?.altText || fallbackImage?.imageAltText}
        />
      </picture>
    );
  }
  return null;
};

export default IvTopImage;
