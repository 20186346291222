import { IVImage, checkWebP } from "./imageHelper";

export const getTopImage = (imageData: any, width: number): IVImage => {
  if (checkWebP) {
    const image = getTopImageWebP(imageData, width);
    if (image) {
      return image;
    }
  }
  return getTopImageFallback(imageData, width);
};

const getTopImageWebP = (imageData: any, width: number): IVImage => {
  if (width < 768) {
    if (imageData?.Size768webP) {
      return imageData?.Size768webP
        ? ({
            url: imageData?.Size768webP,
            width: 768,
            height: 432,
            imageAltText: imageData?.altText,
          } as IVImage)
        : null;
    } else {
      return imageData?.thumbUrl
        ? ({
            url: imageData?.thumbUrl,
            width: 768,
            height: 432,
            imageAltText: imageData?.altText,
          } as IVImage)
        : null;
    }
  }

  if (width < 1280) {
    return imageData?.Size1280webP
      ? ({
          url: imageData?.Size1280webP,
          width: 1280,
          height: 360,
          imageAltText: imageData?.altText,
        } as IVImage)
      : null;
  }

  return imageData?.mediaUrlWebP
    ? ({
        url: imageData?.mediaUrlWebP,
        width: 1920,
        height: 540,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
};

const getTopImageFallback = (imageData: any, width: number): IVImage => {
  let image = {} as IVImage;

  const desktopImage = imageData?.mediaUrl
    ? ({
        url: imageData?.mediaUrl,
        width: 1920,
        height: 540,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image1280 = imageData?.Size1280
    ? ({
        url: imageData?.Size1280,
        width: 1240,
        height: 360,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image768 = imageData?.Size768
    ? ({
        url: imageData?.Size768,
        width: 768,
        height: 216,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const mobileImage = imageData?.backup16x9Format
    ? ({
        url: imageData?.backup16x9Format,
        width: 375,
        height: 211,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;

  image =
    width < 376 && mobileImage
      ? mobileImage
      : width < 1024 && image768
      ? image768
      : width < 1920 && image1280
      ? image1280
      : desktopImage || image1280 || image768;
  return image;
};
