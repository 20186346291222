import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import { getContentAreaMaxWidth } from "@utils/pageHelper";

import { Context } from "types/context/Context";
import { RelatedContentContext } from "types/context/RelatedContentContext";

import CFContentArea from "../../components/cFContentArea";
import relatedContentStyles from "./relatedContent.module.css";

interface IRelatedContent {
  heading: string;
  relatedContent: any;
  smallImages?: boolean;
  contentfulPreviewEntryId?: string;
  contentfulPreviewRelatedContentHeadingFieldId?: string;
  context: Context;
}

const RelatedContent = ({
  heading,
  relatedContent,
  smallImages = false,
  contentfulPreviewEntryId,
  contentfulPreviewRelatedContentHeadingFieldId,
  context,
}: IRelatedContent) => {
  const numberOfRelatedContents = relatedContent?.items?.length;
  const size = numberOfRelatedContents < 2 ? 1 : numberOfRelatedContents === 2 ? 2 : 3;
  const sectionItemsHeadingVariant = heading ? "h3" : "h2";

  const relatedContentContext: RelatedContentContext = {
    smallImages: smallImages,
  };
  context = {
    ...context,
    relatedContent: relatedContentContext,
    columns: relatedContent?.items?.length,
  };

  return (
    <Grid container alignItems="center" className={relatedContentStyles.relatedContent}>
      <Grid container className={relatedContentStyles.contentWrapper}>
        {heading && (
          <Heading
            variant="h2"
            children={heading}
            className={relatedContentStyles.heading}
            data-contentful-entry-id={contentfulPreviewEntryId}
            data-contentful-field-id={contentfulPreviewRelatedContentHeadingFieldId}
          />
        )}
        <CFContentArea
          key="main-area"
          mobileColumns={1}
          tabletColumns={1}
          notebookColumns={size}
          desktopColumns={size}
          contentAreaItems={relatedContent?.items}
          context={context}
          maxWidth={getContentAreaMaxWidth(12)}
          heading={sectionItemsHeadingVariant}
        />
      </Grid>
    </Grid>
  );
};

export default RelatedContent;
