import NextImage from "next/image";
import { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Grid from "@afa-shared/afa-components/dist/Grid";
import TopImage from "@afa-shared/afa-components/dist/TopImage";
import IvTopImage from "@components/Medias/Image/IvTopImage";
import PhotoInfo from "@components/PhotoInfo/PhotoInfo";
import RelatedContent from "@components/RelatedContent/RelatedContent";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";

import { Context } from "types/context/Context";

import pageWrapperStyles from "./PageWrapper.module.css";

type PressImage = {
  url?: string;
  caption?: string | null;
  title?: string | null;
  copyright?: string | null;
};

export interface IPageContent {
  imageData?: any;
  pressImage?: PressImage;
  topImageText: string;
  columns: number;
  children: ReactNode;
  relatedContentHeading?: string;
  relatedContentCollection?: any;
  relatedContentSmallImages?: boolean;
  context: Context;
  useReCapcha: boolean;
  shouldRemoveTopMargin?: boolean;
  shouldRemoveBottomMargin: boolean;
  pageWithHeroBlock?: boolean;
  contentfulPreviewEntryId?: string;
  contentfulPreviewImageFieldId?: "ivMainImage";
  contentfulPreviewImageTextFieldId?: "topImageText";
  contentfulPreviewRelatedContentHeadingFieldId?: "relatedContentHeading";
  className?: string;
}

const PageContent = ({
  imageData,
  pressImage,
  topImageText,
  children,
  relatedContentHeading,
  relatedContentCollection,
  relatedContentSmallImages,
  context,
  useReCapcha,
  shouldRemoveTopMargin,
  shouldRemoveBottomMargin,
  pageWithHeroBlock,
  contentfulPreviewEntryId,
  contentfulPreviewImageFieldId,
  contentfulPreviewImageTextFieldId,
  contentfulPreviewRelatedContentHeadingFieldId,
  className,
}: IPageContent) => {
  const metadata = getImageMetaData(imageData);

  return (
    <div className={`${pageWrapperStyles.pageWrapper} ${className}`}>
      <Grid
        className={pageWrapperStyles.topImageWrapper}
        container
        alignItems={"center"}
        margin={"0 auto"}
        data-contentful-entry-id={contentfulPreviewEntryId}
        data-contentful-field-id={contentfulPreviewImageFieldId}
      >
        {pressImage?.url ? (
          <div className={pageWrapperStyles.pressreleaseTopImage}>
            <NextImage
              src={pressImage?.url}
              fill
              alt={pressImage?.caption}
              style={{ objectFit: "cover" }}
              priority
              unoptimized
            />
          </div>
        ) : (
          imageData && (
            <TopImage nextImage={<IvTopImage image={imageData} />} altText={metadata?.altText} />
          )
        )}
      </Grid>

      <Grid
        className={pageWrapperStyles.topImageInfo}
        container
        alignItems={"center"}
        margin={"0 auto"}
      >
        <div className={pageWrapperStyles.photoInfo}>
          <PhotoInfo
            description={topImageText}
            photographer={metadata?.photographer}
            contentfulPreviewEntryId={contentfulPreviewEntryId}
            contentfulPreviewImageTextFieldId={contentfulPreviewImageTextFieldId}
          />
        </div>
      </Grid>

      <Grid
        container
        alignItems={"center"}
        id={pageWithHeroBlock ? "" : "innehall"}
        className={`${pageWrapperStyles.contentWrapper} ${
          shouldRemoveTopMargin ? "" : pageWrapperStyles.hasTopMargin
        } ${shouldRemoveBottomMargin ? "" : pageWrapperStyles.hasBottomMargin} ${
          imageData || pressImage?.url ? pageWrapperStyles.hasTopImage : ""
        } ${className}`}
      >
        {useReCapcha ? (
          <GoogleReCaptchaProvider
            language="sv-SE"
            reCaptchaKey="6Lct_94nAAAAAFzcHHn1Ry8TnZ8Y5tKBydSnViT8"
            scriptProps={{
              async: true,
            }}
          >
            {children}
          </GoogleReCaptchaProvider>
        ) : (
          <>{children}</>
        )}
      </Grid>

      {relatedContentCollection?.items?.length > 0 && (
        <RelatedContent
          heading={relatedContentHeading}
          relatedContent={relatedContentCollection}
          smallImages={relatedContentSmallImages}
          contentfulPreviewEntryId={contentfulPreviewEntryId}
          contentfulPreviewRelatedContentHeadingFieldId={
            contentfulPreviewRelatedContentHeadingFieldId
          }
          context={context}
        />
      )}
    </div>
  );
};

export default PageContent;
