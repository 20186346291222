import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { INLINES } from "@contentful/rich-text-types";
import { getFileSize } from "@utils/string";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

interface IRichTextRendererProps {
  json: any;
  links?: any;
  pageTextArea?: boolean;
}
interface linkProps {
  url: string;
  name: string;
}

function richTextOptions(links: any) {
  const entryMap = new Map();
  const linkMap = new Map();
  const domain = "";

  if (links?.entries) {
    if (links?.entries?.block) {
      for (const entry of links?.entries?.block) {
        entryMap.set(entry?.sys?.id, entry);
      }
    }
    if (links?.entries?.hyperlink) {
      for (const link of links?.entries?.hyperlink) {
        linkMap.set(link?.sys?.id, link);
      }
    }
  }

  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const linkName = node?.content[0]?.value ? node?.content[0]?.value : node?.data?.uri;
        return `<a href=&#34;${node?.data?.uri}&#34;>${linkName}</a>`;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = linkMap?.get(node?.data?.target.sys.id);

        if (link?.__typename === "FileBlock") {
          const file = link?.imageVaultFile && link?.imageVaultFile[0];

          const fileExtension = file?.fileExtension?.toUpperCase().slice(0, 3);
          const fileSize = getFileSize(file?.fileSize);

          const fileData =
            fileExtension && fileSize
              ? ` (${fileExtension}, ${fileSize})`
              : fileExtension
              ? ` (${fileExtension})`
              : fileSize
              ? ` (${fileSize})`
              : "";
          const linkText = node?.content[0]?.value
            ? node?.content[0]?.value + fileData
            : "fil" + fileData;

          return (
            file &&
            `<a href=&#34;${domain}${replaceImageVaultBasePath(
              file?.mediaUrl
            )}&#34;>${linkText}</a>`
          );
        } else {
          const linkData: linkProps = {
            url: link?.url,
            name: node?.content[0]?.value || link?.url,
          };
          return (
            linkData?.url && `<a href=&#34;${domain}${linkData?.url}&#34;>${linkData?.name}</a>`
          );
        }
      },
    },
  };
}

export const RichTextHtmlHelper = ({ json, links }: IRichTextRendererProps) => {
  return documentToHtmlString(json, richTextOptions(links)).replace(/(\r\n|\n|\r)/gm, "");
};
