import { RichTextHtmlHelper } from "@utils/richTextHtmlHelper";

export const getFaqStructuredData = (faqs) => {
  const questions = faqs?.map((faq) => {
    return getStructuredFaq(faq);
  });
  return `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[${questions}]}`;
};

const getStructuredFaq = (faq) => {
  const html = { json: faq?.answer?.json, links: faq?.answer?.links };
  return `{"@type":"Question","name":"${
    faq?.question
  }","acceptedAnswer":{"@type":"Answer","text":"${RichTextHtmlHelper(html)}"}}`;
};
