import Head from "next/head";
import { useEffect, useState } from "react";

import { CookieConsentCategory, useCookieConsent } from "hooks/useCookieConsent";

import { getFaqStructuredData } from "./faqStructuredData";

interface IMeta {
  title: string;
  keywords?: string;
  description: string;
  image?: string;
  imageAlt?: string;
  ogTitle?: string;
  url?: string;
  path?: string;
  canonicalUrl?: string;
  linkedFaqs?: any;
  noIndex?: boolean;
  noFollow?: boolean;
}

const Meta = ({
  title,
  keywords,
  description,
  image,
  imageAlt,
  ogTitle,
  url,
  path,
  canonicalUrl,
  linkedFaqs,
  noIndex,
  noFollow,
}: IMeta): JSX.Element => {
  const necessaryCookieConsentGiven = useCookieConsent(CookieConsentCategory.necessary);
  const marketingCookieConsentGiven = useCookieConsent(CookieConsentCategory.marketing);
  const statisticalCookieConsentGiven = useCookieConsent(CookieConsentCategory.statistical);

  const [previousNecessaryCookieConsentGiven, setPreviousNecessaryCookieConsentGiven] =
    useState<boolean>(necessaryCookieConsentGiven);
  const [previousMarketingCookieConsentGiven, setPreviousMarketingCookieConsentGiven] =
    useState<boolean>(marketingCookieConsentGiven);
  const [previousStatisticalCookieConsentGiven, setPreviousStatisticalCookieConsentGiven] =
    useState<boolean>(statisticalCookieConsentGiven);

  const [loadGtmScript, setLoadGtmScript] = useState<boolean>();

  const noindexValue = noIndex ? "noindex" : "";
  const nofollowValue = noFollow ? "nofollow" : "";
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.afaforsakring.se/",
    name: "Afa Försäkring",
    description:
      "Afa Försäkring ger trygghet genom kollektivavtalad försäkring vid sjukdom, arbetsskada, arbetsbrist, dödsfall och föräldraledighet. Vi ägs av Svenskt Näringsliv, LO och PTK och försäkrar 5 miljoner människor i Sverige.",
  };

  useEffect(
    function gtmScript() {
      if (marketingCookieConsentGiven && statisticalCookieConsentGiven) {
        setLoadGtmScript(true);
      }
    },
    [marketingCookieConsentGiven, statisticalCookieConsentGiven]
  );

  useEffect(
    function reloadOnConsentRevoked() {
      const undefinedDisabled = !necessaryCookieConsentGiven && previousNecessaryCookieConsentGiven;
      const marketingDisabled = !marketingCookieConsentGiven && previousMarketingCookieConsentGiven;
      const statisticalDisabled =
        !statisticalCookieConsentGiven && previousStatisticalCookieConsentGiven;
      if (undefinedDisabled || marketingDisabled || statisticalDisabled) {
        // User had previously given consent but has now withdrawn it and we need to disable things.
        // To unload script already loaded we do a full page reload.
        global.window.location.reload();
      }
      setPreviousNecessaryCookieConsentGiven(necessaryCookieConsentGiven);
      setPreviousMarketingCookieConsentGiven(marketingCookieConsentGiven);
      setPreviousStatisticalCookieConsentGiven(statisticalCookieConsentGiven);
    },
    [
      necessaryCookieConsentGiven,
      marketingCookieConsentGiven,
      statisticalCookieConsentGiven,
      previousNecessaryCookieConsentGiven,
      previousMarketingCookieConsentGiven,
      previousStatisticalCookieConsentGiven,
    ]
  );

  return (
    <Head>
      {(noIndex || noFollow) && (
        <meta
          name="robots"
          content={`${noindexValue}${noIndex && noFollow ? ", " : ""}${nofollowValue}`}
        />
      )}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#020678" />
      <meta name="theme-color" content="#020678" />
      {/* OG: open graph use for example facebook */}
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website"></meta>
      <meta property="twitter:card" content={"summary_large_image"} />
      <meta property="twitter:site" content={"@afaforsakring"} />
      <meta property="twitter:title" content={ogTitle || title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:image:alt" content={imageAlt} />
      <meta property="fb:pages" content="181548588557152" />
      <meta charSet="utf-8" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="canonical" href={canonicalUrl || url} />
      <link rel="preconnect" href="https://www.googletagmanager.com"></link>
      <link rel="preconnect" href="https://static.hotjar.com"></link>
      <title>{title}</title>
      {path && path === "/" && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      )}
      {linkedFaqs && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getFaqStructuredData(linkedFaqs) }}
        />
      )}
      {loadGtmScript ? (
        // eslint-disable-next-line @next/next/no-sync-scripts
        <script key="gtm-script" id="gtm-script" src="/gtm.js" async />
      ) : null}
    </Head>
  );
};

export default Meta;
